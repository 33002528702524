import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from '@reach/router'

import Loader from '../../Loader'
import useStore from './useStore'
import useCMS from './useCMS'

const Address = props => {
  let ticker = useRef(null)
  const [dirty, setDirty] = useState()
  const [loading, setLoading] = useState()
  const location = useLocation()
  const cms = useCMS()
  const {
    input,
    error,
    results,
    type,
    update
  } = useStore(state => ({
    input: state.input,
    error: state.error,
    results: state.results || [],
    type: state.type,
    update: state.update,
  }))
  const { t } = useTranslation()

  const select = result => {
    try {
      let { lat, lng } = result.geometry?.location
      if (lat?.constructor === Function) {
        lat = lat()
      }
      if (lng?.constructor === Function) {
        lng = lng()
      }
      const coords = [lat, lng].filter(Boolean).join(',')
      setTimeout(() => {
        update({ coords, address: result.formatted_address, type: 'input' })
      }, 100)

    } catch {}
  }

  const onChange = ({ target: { value } }) => {
    if (!dirty) setDirty(true)
    clearTimeout(ticker.current)
    setLoading(true)
    update({ input: value })
  }

  useEffect(() => {
    if (dirty) {
      const value = input
      ticker.current = setTimeout(async () => {
        const key = value?.toString()?.toLowerCase()?.replace(/[\W_]+/g, '') || ''
        if (key.length < 4) return setLoading(false)
        const results = await window.places.find(value).then(res => res.results || [])
        if (input !== value) return setLoading(false)
        update({ results })
        setLoading(false)
      }, 300)
    }
    return () => clearTimeout(ticker.current)
  }, [input, dirty])

  if (!cms) return null

  const params = new URLSearchParams(location.search).toString()

  return (
    <div className={`${props.className || ''} relative`}>
      <input
        type="search"
        value={input || ''}
        onChange={onChange}
        className="
          bg-[#F3F3F3] rounded-[13px] border-transparent text-[12px] leading-5 p-3.5 placeholder:text-secondary/50 w-full pl-11
          focus:text-secondary/80
        "
        placeholder={t('location_finder_input_placeholder', 'Lugar, zona, barrio o direccion')}
      />
      <div className="absolute top-0 mt-3.5 ml-4">
        {loading ? (
          <Loader />
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        )}

      </div>
      {results.length > 0 && (
        <div className="flex flex-col gap-y-2 mt-2 mx-2">
          <div>{t('location_finder_results', 'Resultados de tu busqueda')}</div>
          {results.map(result => (
            <Link
              onClick={() => select(result)}
              to={[cms?.country?.slug, '/input', cms?.categories?.current?.slug, params ? `?${params}` : null].filter(Boolean).join('')}
              key={result.place_id}
              className="
                text-black w-full text-left
                p-3 text-sm bg-gray-50/80 rounded-[5px] border
              "
            >{result.formatted_address}</Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default Address
