import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";
import useCMS from "../useCMS";
import { has, uniq } from "lodash";
import Address from "../Address";
import Position from "../Position";
import useStore from "../useStore";

const FiltersZones = (props) => {
  const [entries, setEntries] = useState([]);
  const [zones, showZones] = useState();
  const cms = useCMS();
  const location = useLocation();
  const { t } = useTranslation();

  const { update, address } = useStore(state => ({
    address: state.address,
    input: state.input,
    error: state.error,
    results: state.results || [],
    type: state.type,
    update: state.update,
  }))

  useEffect(() => {
    const entries = Object.values(
      cms?.zones?.current?.childrens ||
        cms?.zones?.childrens?.[cms?.country?.id]?.childrens ||
        cms?.zones?.childrens ||
        {}
    );
    setEntries(entries);
  }, [cms]);

  useEffect(() => {
    showZones();
  }, [location.pathname]);

  const handleClick = (entry) => {
    update({type: 'selector', address: undefined});
    if (entry?.childrens) {
      setEntries(Object.values(entry?.childrens));
    } else {
      props.zoneState.setSelectedZone(entry);

      const isDirty = props.zoneState.category
        ? !has(
            cms?.categories?.childrens,
            `${props.zoneState.category
              ?.split("/")
              .filter(Boolean)
              .join(".childrens.")}.childrens`
          )
        : false;

      props.zoneState.setIsValid(isDirty);

      showZones((prev) => !prev);
    }
  };

  if (!cms) return null;

  return (
    <div className="flex flex-col items-center gap-y-6 lg:gap-y-8 w-full sm:max-w-md sm:mx-auto">
      <button
        className="
            group relative
            border border-gray-300/80 py-3 px-6 rounded-[13px] w-full text-left
            hover:border-primary"
        onClick={() => showZones(!zones)}
      >
        <div className="text-[13px] absolute -top-[12px] left-4 rounded-lg bg-white text-secondary/75 border border-transparent">
          {t("location", "Ubicación")}
        </div>
        <div className="font-medium group-hover:text-primary">
          {address || uniq(
            [
              cms?.country?.label,
              props?.zoneState?.selectedZone?.label ||
                cms?.zones?.current?.label,
            ].filter(Boolean)
          ).join(" - ")}
        </div>
      </button>

      {zones && (
        <div className={props.className || "w-full"}>
          {props.title !== false && (
            <div className="flex items-center mb-6">
              <div
                className="text-[19px] font-medium leading-none"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `location_finder_title`,
                    `¿<span class="text-primary">Dónde</span> lo necesitás?`
                  ),
                }}
              />
            </div>
          )}

          {cms.country?.slug && (
            <div className="flex flex-col gap-y-6 mb-6">
              <Address />
              <Position />
              <div
                className="text-[19px] font-medium leading-none"
                dangerouslySetInnerHTML={{
                  __html: t(
                    `location_finder_input_highlight`,
                    `Zonas <span class="text-primary">más buscadas</span>`
                  ),
                }}
              />
            </div>
          )}
          <div className="flex flex-col space-y-4">
            {entries.map((entry) => (
              <button
                className="text-[15px] text-black"
                onClick={() => handleClick(entry)}
                key={entry.slug}
              >
                {entry.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersZones;
